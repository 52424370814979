import styles from './form.module.scss';
import { useMemo } from 'react';

export type Props = {
  label?: string;
  type?: 'password' | 'text';
  name?: string;
  validationError?: string | string[];
  onChange?: () => void;
  defaultValue?: string;
};

export function Input(props: Props) {
  const { label, defaultValue, onChange, type, name, validationError } = props;
  const id = useMemo<string>(() => Math.random().toString(16).substr(2), []);

  return (
    <div className={styles['input']}>
      {label && <label htmlFor={id}>{label}</label>}
      <input
        name={name}
        type={type}
        id={id}
        onChange={onChange}
        defaultValue={defaultValue}
      />
      {validationError &&
        (Array.isArray(validationError)
          ? validationError
          : [validationError]
        ).map((error, index) => (
          <div key={index} className={styles['form__validationError']}>
            {error}
          </div>
        ))}
    </div>
  );
}
