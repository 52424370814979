import { EN } from './languages/en';
import { RU } from './languages/ru';
import { LanguageInterface } from './language.interface';
import { useRouter } from 'next/router';

export const LOCALES = ['en', 'ru'] as const;
export const DEFAULT_LOCALE: typeof LOCALES[number] = 'ru';

export const LOCALISATIONS: Record<typeof LOCALES[number], LanguageInterface> =
  {
    en: EN,
    ru: RU,
  };

export function i18n<
  L extends keyof typeof LOCALISATIONS,
  K extends keyof LanguageInterface,
>(locale: L, key: K): LanguageInterface[K] {
  return LOCALISATIONS[locale][key];
}

function isLocale(locale: string): locale is keyof typeof LOCALISATIONS {
  return locale in LOCALISATIONS;
}

export function useI18n() {
  const router = useRouter();
  const locale =
    router.locale && isLocale(router.locale) ? router.locale : DEFAULT_LOCALE;

  return {
    i18n: <K extends keyof LanguageInterface>(key: K) => {
      return i18n(locale, key);
    },
    locale,
  };
}
