import styles from './styles.module.scss';
import { ReactNode } from 'react';
import { Sidebar } from '../sidebar';
import { clsx } from 'clsx';

export type Props = {
  children: ReactNode[] | ReactNode;
  className?: string;
  rightSidebar?: ReactNode[] | ReactNode;
  leftSidebar?: ReactNode[] | ReactNode;
};

export function Main(props: Props) {
  return (
    <main className={clsx(styles['main'], props.className)}>
      <div className={clsx(styles['main__container'], styles['container'])}>
        <div className={clsx(styles['container__left-sidebar'])}>
          <Sidebar />
          {props.leftSidebar}
        </div>
        <div className={clsx(styles['container__content'])}>
          {props.children}
        </div>
        <div className={clsx(styles['container__right-sidebar'])}>
          {props.rightSidebar}
        </div>
      </div>
    </main>
  );
}
