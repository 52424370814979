import { useMutation, useQueryClient } from 'react-query';
import {
  LoginDto,
  AccessTokenDto,
  useApi,
  useApiCredentials,
} from '../shared/api';

export function useLogin() {
  const api = useApi();
  const { setToken } = useApiCredentials();
  const queryClient = useQueryClient();

  const loginMutation = useMutation<AccessTokenDto, unknown, LoginDto>(
    '/api/user/login',
    async (payload) => {
      const res = await api.auth.login(payload);
      return res.data;
    },
    {
      onSuccess: (data) => {
        const expiresAt = new Date(data.expiresAt);

        setToken({
          accessToken: data.accessToken,
          expiresAt,
        });

        queryClient.invalidateQueries('api/user');
      },
    },
  );

  return loginMutation;
}
