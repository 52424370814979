import styles from './form.module.scss';
import ReactSelect from 'react-select/async-creatable';
import { GroupBase, OnChangeValue } from 'react-select';
import { useCallback, useRef } from 'react';

export type OptionType = { value: string; label: string };
export type GroupType = GroupBase<OptionType>;

export type Props = {
  label?: string;
  name?: string;
  placeholder?: string;
  noOptionsMessage?: string;
  validationError?: string | string[];
  multi?: boolean;
  options?: ReadonlyArray<OptionType | GroupType>;
  onChange?: (values: null | OptionType | OptionType[]) => void;
  defaultValue?: OptionType | OptionType[];
};

export function CreatableSelect(props: Props) {
  const {
    defaultValue,
    onChange,
    placeholder,
    name,
    multi,
    validationError,
    label,
    options,
    noOptionsMessage,
  } = props;
  const inputRef = useRef<HTMLInputElement | null>();

  const onInputRef = useCallback(
    (elem: HTMLInputElement | null) => {
      inputRef.current = elem;

      if (inputRef.current) {
        inputRef.current.value = defaultValue
          ? JSON.stringify(defaultValue)
          : multi
          ? '[]'
          : '';
      }
    },
    [multi, defaultValue],
  );

  const onSelectChange = useCallback(
    (values: OnChangeValue<OptionType, boolean>) => {
      if (inputRef.current) {
        inputRef.current.value = JSON.stringify(values);
      }

      if (onChange) {
        // @ts-expect-error: TODO: fix
        onChange(Array.isArray(values) ? [...values] : values);
      }
    },
    [onChange],
  );

  return (
    <div className={styles['select']}>
      {label && <label>{label}</label>}
      {name && (
        <input ref={onInputRef} type={'hidden'} name={name + '--json'} />
      )}

      <ReactSelect
        placeholder={placeholder}
        noOptionsMessage={() => noOptionsMessage || null}
        defaultValue={defaultValue}
        onChange={onSelectChange}
        options={options}
        isMulti={multi}
        classNamePrefix={'react-select'}
      />
      {validationError &&
        (Array.isArray(validationError)
          ? validationError
          : [validationError]
        ).map((error, index) => (
          <div key={index} className={styles['form__validationError']}>
            {error}
          </div>
        ))}
    </div>
  );
}
