import { useRouter } from 'next/router';
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLogout, useUser as useUserQuery } from '../../user';

export function useUser() {
  const user = useUserQuery();
  const logout = useLogout();
  const [isActive, setIsActive] = useState(false);
  const userRef = useRef<HTMLAnchorElement>(null);
  const router = useRouter();

  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      if (e.target instanceof Node && userRef.current?.contains(e.target)) {
        if (!user.data) {
          router.push('/').catch(console.error);
        } else {
          setIsActive(!isActive);
        }
      } else {
        setIsActive(false);
      }
    };

    const onLinkClick = (e: MouseEvent) => {
      e.preventDefault();
    };

    const linkRef = userRef.current;

    linkRef?.addEventListener('click', onLinkClick);
    window.addEventListener('click', onClick);

    return () => {
      window.removeEventListener('click', onClick);
      linkRef?.removeEventListener('click', onLinkClick);
    };
  }, [user, isActive, router]);

  const onLogout = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      logout.mutate();
    },
    [logout],
  );

  return {
    onLogout,
    userRef,
    isActive,
    user: user.data,
  };
}
