import {
  CSSProperties,
  MutableRefObject,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import styles from './form.module.scss';
import { clsx } from 'clsx';

export type Props<D extends FormValues> = {
  apiRef?: MutableRefObject<FormApi | null>;
  children: ReactNode[] | ReactNode;
  style?: CSSProperties;
  onSubmit?: (data: D) => void;
  className?: string;
};

export type FormItemValue =
  | File
  | string
  | Record<string, string>
  | string[]
  | null;
export type FormValues = Record<string, FormItemValue>;

function createFormValuesObject<T extends FormValues>(data: FormData): T {
  const object: Record<string, FormItemValue> = {};

  for (const item of data.entries()) {
    if (item[0].endsWith('--json') && typeof item[1] === 'string') {
      object[item[0].substr(0, item[0].length - 6)] = !item[1]
        ? null
        : JSON.parse(item[1]);
    } else {
      object[item[0]] = item[1];
    }
  }

  return object as T;
}

export interface FormApi {
  submit: () => void;
}

export function Form<D extends FormValues>(props: Props<D>) {
  const { onSubmit, className, style, apiRef, children } = props;
  const ref = useRef<HTMLFormElement>(null);

  const onFormSubmit = useCallback(
    (e?: SyntheticEvent<HTMLFormElement>) => {
      const formElement = e?.currentTarget || ref.current;

      if (!formElement) {
        return;
      } else if (e) {
        e.preventDefault();
      }

      if (onSubmit) {
        onSubmit(createFormValuesObject<D>(new FormData(formElement)));
      }
    },
    [onSubmit],
  );

  useEffect(() => {
    if (apiRef) {
      apiRef.current = {
        submit: () => {
          onFormSubmit();
        },
      };
    }
  }, [apiRef, onFormSubmit]);

  return (
    <form
      ref={ref}
      method={'post'}
      onSubmit={onFormSubmit}
      style={style}
      className={clsx(styles['form'], className)}
    >
      {children}
    </form>
  );
}
