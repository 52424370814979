import { LanguageInterface } from '../language.interface';

export const RU: LanguageInterface = {
  russianLanguage: 'Русский',
  englishLanguage: 'Английский',
  draftLanguagesIsRequired: 'Заполните это поле',
  publishDraft: 'Опубликовать',
  publishDraftChanges: 'Опубликовать изменения',
  unpublishDraft: 'Распубликовать',
  resetDraft: 'Сбросить',
  previewDraft: 'Предпросмотр',
  deleteDraft: 'Удалить',
  draftLanguages: 'Языки',
  draftPublishDate: 'Дата публикации (ISO)',
  draftTags: 'Тэги',
  selectPlaceholder: 'Выбрать...',
  draftTitlePlaceholder: 'Заголовок',
  draftContentPlaceholder: 'История...',
  continueDraftEditing: 'Править...',
  editDraft: 'Править',
  draftsMenuItem: 'Черновики',
  newDraftMenuItem: 'Новая запись',
  homeMenuItem: 'Домой',
  logOutMenuItem: 'Выйти',
  emptyPreview: 'Отсутствует превью',
  blogSection: 'Блог',
  projectsSection: 'Проекты',
  projectsSectionLink: '/?tags=проект',
  articlesSection: 'Статьи',
  articlesSectionLink: '/?tags=статья',
  loginLabel: 'Логин',
  loginAction: 'Войти',
  passwordLabel: 'Пароль',
  defaultPageTitle: "lsndr's блог",
  draftsPageTitle: 'Черновики',
  newDraftPageTitle: 'Новая запись',
  editDraftPageTitle: 'Редактирование записи',
  loginPageTitle: 'Вход',
  areYouSure: 'Вы уверены?',
  photosSection: 'Фото',
  photosSectionLink: '/?tags=фото',
  translationsSection: 'Переводы',
  translationsSectionLink: '/?tags=перевод',
  thisIsTranslation: 'Это перевод',
  tagsMenu: 'Тэги',
  months: [
    ['Январь', 'Января'],
    ['Февраль', 'Февраля'],
    ['Март', 'Марта'],
    ['Апрель', 'Апреля'],
    ['Май', 'Мая'],
    ['Июнь', 'Июня'],
    ['Июль', 'Июля'],
    ['Август', 'Авугста'],
    ['Сентябрь', 'Сентября'],
    ['Октябрь', 'Октября'],
    ['Ноябрь', 'Ноября'],
    ['Декабрь', 'Декабря'],
  ],
  openPost: 'Открыть пост',
  draftSecret: 'Пароль черновика',
};
