import {
  FormEvent,
  FormEventHandler,
  KeyboardEventHandler,
  useCallback,
  useState,
} from 'react';
import styles from './form.module.scss';
import { clsx } from 'clsx';

export type Props = {
  className?: string;
  name?: string;
  required?: boolean;
  autoSize?: boolean;
  onKeyDown?: KeyboardEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  onInput?: FormEventHandler<HTMLTextAreaElement>;
  defaultValue?: string;
};

export function Textarea(props: Props) {
  const {
    autoSize,
    className,
    defaultValue,
    name,
    placeholder,
    onInput,
    onKeyDown,
    required,
  } = props;
  const [elemData, setElemData] = useState<{
    baseScrollHeight: number;
    fontSize: number;
  }>();

  const updateElementSize = useCallback(
    (
      elem: HTMLTextAreaElement,
      elemData: { baseScrollHeight: number; fontSize: number },
    ) => {
      elem.rows = 1;
      elem.rows =
        Math.ceil(
          (elem.scrollHeight - elemData.baseScrollHeight) / elemData.fontSize,
        ) || 1;
    },
    [],
  );

  const onElemRef = useCallback(
    (elem: HTMLTextAreaElement | null) => {
      if (!elem) {
        return;
      }

      const value = elem.value;
      elem.value = '';
      const baseScrollHeight = elem.scrollHeight;
      elem.value = value;

      // TODO: Probably getComputedStyle doesn't always return px. Need to convert
      const fontSize = parseInt(
        getComputedStyle(elem).getPropertyValue('font-size'),
        10,
      );

      setElemData({
        fontSize,
        baseScrollHeight,
      });

      updateElementSize(elem, {
        fontSize,
        baseScrollHeight,
      });
    },
    [updateElementSize],
  );

  const onTextareaInput = useCallback(
    (e: FormEvent<HTMLTextAreaElement>) => {
      if (onInput) {
        onInput(e);
      }

      if (!autoSize || typeof elemData === 'undefined') {
        return;
      }

      updateElementSize(e.currentTarget, elemData);
    },
    [autoSize, elemData, updateElementSize, onInput],
  );

  return (
    <div
      className={clsx(
        styles['textarea'],
        autoSize && styles['textarea_autosizeable'],
        className,
      )}
    >
      <textarea
        defaultValue={defaultValue}
        ref={onElemRef}
        rows={autoSize ? 1 : 0}
        onInput={onTextareaInput}
        name={name}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        required={required}
      />
    </div>
  );
}
