import styles from './form.module.scss';
import { forwardRef, MouseEventHandler, ReactNode, useCallback } from 'react';
import { FiLoader } from 'react-icons/fi';
import { clsx } from 'clsx';
import Link from 'next/link';

export interface BaseProps<E> {
  children: ReactNode[] | ReactNode;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  round?: boolean;
  block?: boolean;
  onClick?: MouseEventHandler<E>;
  style?: 'outlined' | 'void' | 'classic' | 'danger';
}

export interface ButtonProps extends BaseProps<HTMLButtonElement> {
  type: 'submit' | 'button';
}

export interface LinkProps extends BaseProps<HTMLAnchorElement> {
  type: 'link';
  href: string;
  target?: '_blank';
}

export const Button = forwardRef<any, LinkProps | ButtonProps>((props, ref) => {
  const { onClick, className, round, style, disabled, loading, block } = props;

  const onElemClick = useCallback(
    (e: any) => {
      if (disabled || loading || !onClick) {
        return;
      }

      onClick(e);
    },
    [onClick, disabled, loading],
  );

  const baseProps = {
    ref,
    onClick: onElemClick,
    className: clsx(
      styles['button'],
      style === 'danger' && styles['button_danger'],
      style === 'void' && styles['button_void'],
      style === 'outlined' && styles['button_outlined'],
      loading && styles['button_spinning'],
      className,
      round && styles['button_round'],
      block && styles['button_block'],
    ),
  };

  if (props.type === 'link') {
    return (
      <Link {...baseProps} href={props.href} target={props.target}>
        {props.loading ? <FiLoader size={'1em'} /> : props.children}
      </Link>
    );
  } else {
    return (
      <button {...baseProps} type={props.type}>
        {props.loading ? <FiLoader size={'1em'} /> : props.children}
      </button>
    );
  }
});

Button.displayName = Object.keys({ Button })[0];
