import { useMutation, useQueryClient } from 'react-query';
import { useApi, useApiCredentials } from '../shared/api';

export function useLogout() {
  const api = useApi();
  const { setToken } = useApiCredentials();
  const queryClient = useQueryClient();

  const logoutMutation = useMutation(
    '/api/user/login',
    async () => {
      await api.auth.logout();
    },
    {
      onSuccess: () => {
        setToken(null);
        queryClient.setQueriesData('api/user', undefined);
      },
    },
  );

  return logoutMutation;
}
