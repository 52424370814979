import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useApi, useApiCredentials, useApiQuery } from '../shared/api';

type Props = {
  redirectAuthedTo?: string;
  redirectUnauthedTo?: string;
  staleTime?: number;
  refetchInterval?: number;
};

export function useUser(props: Props = {}) {
  const {
    redirectAuthedTo,
    redirectUnauthedTo,
    staleTime = 60000,
    refetchInterval = 60000,
  } = props;
  const router = useRouter();
  const api = useApi();
  const { setToken, hasToken } = useApiCredentials();

  const userQuery = useApiQuery(
    'api/user',
    async () => {
      try {
        const res = await api.user.getMe();
        return res.data;
      } catch (e) {
        if (e instanceof Response && e.status === 403) {
          setToken(null);
          return;
        }

        throw e;
      }
    },
    {
      enabled: hasToken,
      staleTime,
      refetchInterval,
    },
  );

  useEffect(() => {
    if (
      (!hasToken || (!userQuery.isLoading && !userQuery.data)) &&
      redirectUnauthedTo
    ) {
      router.push(redirectUnauthedTo);
    } else if (!userQuery.isLoading && userQuery.data && redirectAuthedTo) {
      router.push(redirectAuthedTo);
    }
  }, [userQuery, hasToken, redirectAuthedTo, redirectUnauthedTo, router]);

  return userQuery;
}
