import { LanguageInterface } from '../language.interface';

export const EN: LanguageInterface = {
  russianLanguage: 'Russian',
  englishLanguage: 'English',
  draftLanguagesIsRequired: 'This field is required',
  publishDraft: 'Publish',
  publishDraftChanges: 'Publish changes',
  unpublishDraft: 'Unpublish',
  photosSection: 'Photos',
  photosSectionLink: '/?tags=photo',
  resetDraft: 'Revert',
  previewDraft: 'Preview',
  deleteDraft: 'Delete',
  draftLanguages: 'Languages',
  draftPublishDate: 'Publication date (ISO)',
  draftTags: 'Tags',
  selectPlaceholder: 'Select...',
  draftTitlePlaceholder: 'Title',
  draftContentPlaceholder: 'Your story...',
  continueDraftEditing: 'Edit...',
  editDraft: 'Edit',
  draftsMenuItem: 'Drafts',
  newDraftMenuItem: 'New draft',
  homeMenuItem: 'Home',
  logOutMenuItem: 'Log out',
  emptyPreview: 'Empty preview',
  blogSection: 'Blog',
  projectsSection: 'Projects',
  projectsSectionLink: '/?tags=project',
  articlesSection: 'Articles',
  articlesSectionLink: '/?tags=article',
  translationsSection: 'Translations',
  translationsSectionLink: '/?tags=translation',
  loginLabel: 'Login',
  loginAction: 'Log in',
  passwordLabel: 'Password',
  defaultPageTitle: "lsndr's blog",
  draftsPageTitle: 'Drafts',
  newDraftPageTitle: 'New draft',
  editDraftPageTitle: 'Edit draft',
  thisIsTranslation: 'This is translation',
  loginPageTitle: 'Login',
  areYouSure: 'Are you sure?',
  tagsMenu: 'Tags',
  months: [
    ['January', 'January'],
    ['February', 'February'],
    ['March', 'March'],
    ['April', 'April'],
    ['May', 'May'],
    ['June', 'June'],
    ['July', 'July'],
    ['August', 'August'],
    ['September', 'September'],
    ['October', 'October'],
    ['November', 'November'],
    ['December', 'December'],
  ],
  openPost: 'Open post',
  draftSecret: 'Draft secret',
};
