import styles from './styles.module.scss';
import Link from 'next/link';
import { User } from '../user';
import { RiMailLine, RiInstagramLine, RiGithubFill, RiTelegramLine, } from 'react-icons/ri';
import { useRouter } from 'next/router';
import { useI18n } from '../../i18n';
import { clsx } from 'clsx';

export function Sidebar() {
  const { i18n } = useI18n();
  const router = useRouter();

  return (
    <div className={styles['sidebar']}>
      <User className={clsx(styles['sidebar__logo'])} />
      <ul className={clsx(styles['sidebar__menu'], styles['menu'])}>
        <li className={styles['menu__title']}>{i18n('tagsMenu')}</li>
        <li className={styles['menu__item']}>
          <Link
            className={styles['menu__link']}
            href={i18n('articlesSectionLink')}
          >
            #{i18n('articlesSection')}
          </Link>
        </li>
        <li className={styles['menu__item']}>
          <Link
            className={styles['menu__link']}
            href={i18n('projectsSectionLink')}
          >
            #{i18n('projectsSection')}
          </Link>
        </li>
        <li className={styles['menu__item']}>
          <Link
            className={styles['menu__link']}
            href={i18n('photosSectionLink')}
          >
            #{i18n('photosSection')}
          </Link>
        </li>
        <li className={styles['menu__item']}>
          <Link
            className={styles['menu__link']}
            href={i18n('translationsSectionLink')}
          >
            #{i18n('translationsSection')}
          </Link>
        </li>
      </ul>

      <ul className={clsx(styles['sidebar__contacts'], styles['contacts'])}>
        <li className={styles['contacts__item']}>
          <a href="mailto:alexander@lsndr.dev">
            <RiMailLine />
          </a>
        </li>
        <li className={styles['contacts__item']}>
          <a
            href="https://instagram.com/sasharv"
            target={'_blank'}
            rel="noreferrer"
          >
            <RiInstagramLine />
          </a>
        </li>
        <li className={styles['contacts__item']}>
          <a href="https://t.me/someoneiswrong" target={'_blank'} rel="noreferrer">
            <RiTelegramLine />
          </a>
        </li>
        <li
          className={clsx(
            styles['contacts__item'],
            styles['contacts__item--hide-on-mobile'],
          )}
        >
          <a href="https://github.com/lsndr" target={'_blank'} rel="noreferrer">
            <RiGithubFill />
          </a>
        </li>
      </ul>

      <ul className={clsx(styles['sidebar__languages'], styles['languages'])}>
        <li
          className={clsx(
            styles['languages__item'],
            router.locale === 'ru' && styles['languages__item_active'],
          )}
        >
          {typeof process.env['NEXT_PUBLIC_RU_DOMAIN'] === 'string' ? (
            <a
              href={'//' + process.env['NEXT_PUBLIC_RU_DOMAIN']}
              className={styles['languages__link']}
            >
              RU
            </a>
          ) : (
            <Link
              className={styles['languages__link']}
              href={'/'}
              locale={'ru'}
            >
              RU
            </Link>
          )}
        </li>
        <li
          className={clsx(
            styles['languages__item'],
            router.locale === 'en' && styles['languages__item_active'],
          )}
        >
          {typeof process.env['NEXT_PUBLIC_EN_DOMAIN'] === 'string' ? (
            <a
              href={'//' + process.env['NEXT_PUBLIC_EN_DOMAIN']}
              className={styles['languages__link']}
            >
              EN
            </a>
          ) : (
            <Link
              className={styles['languages__link']}
              href={'/'}
              locale={'en'}
            >
              EN
            </Link>
          )}
        </li>
      </ul>
    </div>
  );
}
