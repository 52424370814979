import styles from './styles.module.scss';
import Link from 'next/link';
import Image from 'next/image';
import { useUser } from './use-user';
import { useI18n } from '../../i18n';
import { clsx } from 'clsx';
import Logo from '../../../public/logo.jpg';

export type Props = {
  className?: string;
};

export function User(props: Props) {
  const { isActive, userRef, user, onLogout } = useUser();
  const { i18n } = useI18n();

  return (
    <div
      className={[
        styles['user'],
        props.className,
        isActive && styles['user__active'],
      ].join(' ')}
    >
      <Link
        href={'/'}
        ref={userRef}
        className={clsx(
          styles['user__icon'],
          user && styles['user__icon--active'],
        )}
      >
        <Image
          className={styles['logo']}
          alt={''}
          src={Logo}
          quality={90}
          fill={true}
          priority={true}
        />
      </Link>
      {user && (
        <ul className={styles['user__menu']}>
          <li>
            <Link className={styles['user__menu-link']} href={'/'}>
              {i18n('homeMenuItem')}
            </Link>
          </li>
          <li>
            <Link className={styles['user__menu-link']} href={'/draft/new'}>
              {i18n('newDraftMenuItem')}
            </Link>
          </li>
          <li>
            <Link className={styles['user__menu-link']} href={'/drafts'}>
              {i18n('draftsMenuItem')}
            </Link>
          </li>
          <li>
            <a
              href={'#'}
              onClick={onLogout}
              className={styles['user__menu-link']}
            >
              {i18n('logOutMenuItem')}
            </a>
          </li>
        </ul>
      )}
    </div>
  );
}
